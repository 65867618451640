.content {
  width: 100%;
}

.loading_box {
  width: 100%;
  height: 20px;
  border: 1px solid var(--grey-color-5);
  border-radius: 4px;
  box-sizing: border-box;
  background-color: var(--grey-color-5);
  overflow: hidden;
}

.loading {
  height: 100%;
  background-color: var(--blue-color);
  border-radius: 4px 0 0 4px;
}
