.content {
  display: flex;
  flex-direction: column;
  max-width: 544px;
  margin: 94px auto 210px;
}

.title {
  margin-bottom: 48px;
}

.avatar {
  width: 88px;
  height: 88px;
  border-radius: 50%;
  margin-bottom: 32px;
  background-color: var(--grey-color-3);
}

.img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.h2 {
  margin-top: 64px;
}

.btn {
  width: 256px;
  align-self: flex-end;
}

.form {
  display: flex;
  flex-direction: column;
}

.caption {
  opacity: .6;
  text-align: right;
}

.file_input_hidden {
  display: none;
}

.file_input {
  background: url("../../images/icons/attach.svg") no-repeat scroll 508px 12px;
  background-color: var(--grey-color-3);
  width: 100%;
  padding: 12px 16px;
  border-radius: var(--button-border-radius);
  box-sizing: border-box;
  color: var(--grey-color-9);
  cursor: pointer;
}

.success_message {
  color: green;
  opacity: .8;
  margin-top: 10px;
  text-align: center;
  margin-top: 0;
  margin-bottom: 10px;
}

.fail_message {
  color: red;
  opacity: .8;
  margin-top: 10px;
  text-align: center;
  margin-top: 0;
  margin-bottom: 10px;
}
