.button {
  display: flex;
  align-items: center;
  border: none;
  outline: none;
  background: none;
  transition: var(--common-transition);
  box-sizing: border-box;
  cursor: pointer;
  padding: 0;
  margin-bottom: 10px;
}

.button:hover {
  opacity: 0.85;
}

.text {
  margin-top: -8px;
}
