.content {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 40px;
  background-color: var(--background);
  z-index: 2;
  border-radius: var(--button-border-radius);
  box-sizing: border-box;
}

.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  background-color: rgba(229, 229, 229, 0.5);
}

.close_btn {
  position: absolute;
  top: 40px;
  right: 40px;
  border: none;
  outline: none;
  background: none;
  transition: var(--common-transition);
  cursor: pointer;
  z-index: 2;
}

.close_btn:hover {
  opacity: .7;
}
