.content {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.label {
  opacity: .6;
}

.input {
  outline: none;
  border: none;
  border-radius: var(--button-border-radius);
  width: 100%;
  padding: 12px 16px;
  background-color: var(--grey-color-3);
  box-sizing: border-box;
  cursor: pointer;
}

.input:focus {
  outline: 1px solid var(--blue-color);
}

.input:focus:invalid {
  outline: 1px solid var(--red-bg-color);
}

input::placeholder {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: var(--grey-color-9);
}

.error {
  opacity: .6;
}

.eye_btn {
  position: absolute;
  top: 40px;
  right: 8px;
  border: none;
  outline: none;
  background: none;
  cursor: pointer;
}
