.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.label {
  opacity: .6;
}

.input {
  outline: none;
  border: none;
  border-radius: var(--card-border-radius);
  width: 100%;
  height: 136px;
  padding: 12px 16px;
  background-color: var(--grey-color-3);
  box-sizing: border-box;
  cursor: pointer;
}

.textarea {
  resize: vertical;
}

.input:focus {
  border: 1px solid var(--blue-color);
}

.input:invalid:focus {
  border: 1px solid var(--red-bg-color);
}

input::placeholder {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: var(--grey-color-9);
}

.error {
  opacity: .6;
}
