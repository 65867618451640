.content {
  width: 256px;
  padding: 0;
  border-radius: var(--card-border-radius);
  box-shadow: 0px 6px 20px rgba(141, 141, 141, 0.25);
  display: flex;
  flex-direction: column;
  cursor: pointer;
  overflow: hidden;
}

.img_box {
  width: 100%;
  height: 192px;
  background-color: var(--grey-color-7);
  text-decoration: none;
  color: var(--text-primary-color)
}

.img {
  width: 100%;
  height: 100%;
}

.data_box {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
}

.name {
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.btn {
  margin-top: 40px;
  margin-bottom: 10px;
}
